import React, { useState } from 'react';
import { Box, Card, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { GET_SIZE } from "utils/responsive";
import MISButton from 'components/common/MISButton';
import { useLocation } from 'react-router-dom';
import MISNoData from 'components/common/MISNoData';
import { ERROR, NETSMARTZ_THEME_COLOR, SUCCESS } from 'theme/colors';
import InfoIcon from "@mui/icons-material/Info";
import { get } from "utils/lodash";

// Convert underlined String to uppercase String
const convertToTitleCase = (str) => {
	return str.replace(/_/g, " ").replace(/\b\w/g, (char) => {
		return char.toUpperCase();
	});
}

const AssessmentEvaluation = () => {
	const [createdAt, setCreatedAt] = useState(null);
	const { isXs } = GET_SIZE();
	const { state } = useLocation();
	const videoInterviewMockResponseDtos = get(state, "mockResponses", [])
	const videoInterviewTechResponseDto = get(state, "techResponse", {});

	const techAssessParamsArray = ["question", "logical"];
	let videoInterviewTechResponseArray = [];
	techAssessParamsArray.map(item => videoInterviewTechResponseArray.push({}))
	techAssessParamsArray.map((param, index) => {
		Object.keys(videoInterviewTechResponseDto).filter(item => item.includes(param)).map(item => videoInterviewTechResponseArray[index] = ({ ...videoInterviewTechResponseArray[index], [item]: videoInterviewTechResponseDto[item] }))
	})

	const coloredCard = (item) => {
		return (
			<Box
				sx={{
					minHeight: "106px",
					minWidth: "170px",
				}}
			>
				<Card
					sx={{
						background: "background.white",
						height: "45%",
						margin: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						boxShadow: "10px 10px 30px -10px rgba(0,0,0,0.2)",
						minHeight: "inherit",
						minWidth: "inherit",
						alignItems: "center",
						position: "relative",
						padding: "10px",
						borderRadius: "8px",
					}}
				>
					<Stack display={"flex"} alignItems={"center"} justifyContent={"center"}>
						<Typography variant="h6" margin="auto" textAlign={"center"} sx={{ fontWeight: "bold", fontSize: "20px" }}>
							{convertToTitleCase(item.title)}
						</Typography>
					</Stack>
					<Box width={"100%"}>
						<Divider />
						<Box sx={{ display: "flex", justifyContent: "space-between", mt: 0.8 }}>
							{item.level < 3 ? (
								<Typography sx={{ fontWeight: "bold" }} color={ERROR.main}>
									Level {item.level}
								</Typography>
							) : item.level === "3" ? (
								<Typography sx={{ color: NETSMARTZ_THEME_COLOR, fontWeight: "bold" }}>Level {item.level}</Typography>
							) : (
								<Typography sx={{ fontWeight: "bold" }} color={SUCCESS.main}>
									Level {item.level}
								</Typography>
							)}
							<Tooltip title={<span style={{ width: "50px", fontSize: "13px" }}>{item.desc}</span>}>
								<InfoIcon className="info_ic" fontSize="medium" sx={{ ml: 1, cursor: "pointer", color: "gray" }} />
							</Tooltip>
						</Box>
					</Box>
				</Card>
			</Box>
		);

	};
	const techColoredCard = (item) => {

		// Determine the title and score based on the item type
		const itemTitle = Object.keys(item).length !== 0 ? convertToTitleCase(Object.keys(item)[0]) : "";
		const itemScore = Object.keys(item).length !== 0 ? item[Object.keys(item)[1]] : "";
		const itemDescription = Object.keys(item).length !== 0 ? item[Object.keys(item)[0]] : "";

		const scoreValue = parseInt(itemScore.split("/")[0]);

		// Determine the color based on the score range
		let scoreColor = ERROR.main; // Default to ERROR.main
		if (scoreValue >= 3 && scoreValue < 6) {
			scoreColor = NETSMARTZ_THEME_COLOR;
		} else if (scoreValue >= 6) {
			scoreColor = SUCCESS.main;
		}

		return (
			<Box
				sx={{
					minHeight: "106px",
					minWidth: "170px",
				}}
			>
				<Card
					sx={{
						background: "background.white",
						height: "45%",
						margin: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						boxShadow: "10px 10px 30px -10px rgba(0,0,0,0.2)",
						minHeight: "inherit",
						minWidth: "inherit",
						alignItems: "center",
						position: "relative",
						padding: "10px",
						borderRadius: "8px",
					}}
				>
					<Stack display={"flex"} alignItems={"center"} justifyContent={"center"}>
						<Typography variant="h6" margin="auto" textAlign={"center"} sx={{ fontWeight: "bold", fontSize: "20px" }}>
							{itemTitle}
						</Typography>
					</Stack>
					<Box width={"100%"}>
						<Divider />
						<Box sx={{ display: "flex", justifyContent: "space-between", mt: 0.8 }}>
							<Typography sx={{ fontWeight: "bold" }} color={scoreColor}>
								Score: {itemScore}
							</Typography>
							<Tooltip title={<span style={{ width: "50px", fontSize: "13px" }}>{itemDescription}</span>}>
								<InfoIcon className="info_ic" fontSize="medium" sx={{ ml: 1, cursor: "pointer", color: "gray" }} />
							</Tooltip>
						</Box>
					</Box>
				</Card>
			</Box>
		);
	};


	return (
		<Stack spacing={3} p={2}>
			{/* Set 1 */}
			<Box>
				<Typography variant="h5" sx={{ mb: 1 }}>
					{" Set 1"}
				</Typography>
				<Box ml={3} mt={3}>
					{/* <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                        Last Updated: {createdAt}
                    </Typography> */}
				</Box>
				<Grid container p={3} pt={1} spacing={3} sx={{ mt: isXs && "30px" }}>
					{videoInterviewMockResponseDtos.length !== 0 ? videoInterviewMockResponseDtos.map((item, index) => (
						<Grid item sm={6} lg={3} xs={12} key={index}>
							{coloredCard(item)}
						</Grid>
					)) : <MISNoData />}
				</Grid>
			</Box>
			{/* Set 2 */}
			<Box>
				<Typography variant="h5" sx={{ mb: 1 }}>
					{" Set 2"}
				</Typography>
				<Box ml={3} mt={3}>
					{/* <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                        Last Updated: {createdAt}
                    </Typography> */}
				</Box>
				<Grid container p={3} pt={1} spacing={3} sx={{ mt: isXs && "30px" }}>
					{videoInterviewTechResponseArray.some(item => Object.keys(item).length > 0) ? videoInterviewTechResponseArray.map((item, index) => (
						<Grid item sm={6} lg={3} xs={12} key={index}>
							{techColoredCard(item)}
						</Grid>
					)) : <MISNoData />}
				</Grid>
			</Box>
		</Stack>
	);
}

export default AssessmentEvaluation;
