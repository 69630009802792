import { useState } from "react";
import PropTypes from "prop-types";
import { TableCell, TableBody, styled, TableRow, Tooltip, IconButton, Grid } from "@mui/material";
import memo from "utils/react";
import { get, noop } from "lodash";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { TitleTypography } from "utils/members";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import T from "T";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 17px",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const MISTableBody = ({
  allTableRows = [],
  deleteProjectOnId = noop,
  isConfirmSubmitOpen = false,
  handleOpenConfirmSubmit = noop,
  handleCloseConfirmSubmit = noop,
}) => {
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState(null);
  const handleEditProject = (id, name) => {
    navigate(`/app/business-central/${id}/edit-project`, { state: { projectHeaderName: name } });
  };
  const handleSubmit = () => {
    deleteProjectOnId(projectId)
  }
  const handleOpen = (id) => {
    setProjectId(id);
    handleOpenConfirmSubmit();
  }
  return (
    <>
      <TableBody>
        {allTableRows.map((record, index) => {
          return (
            <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
              <StyledTableCell>
                <TitleTypography
                  sx={{ textDecoration: "underline", color: NETSMARTZ_THEME_COLOR, cursor: "pointer" }}
                  onClick={() => handleEditProject(get(record, "id", ""), get(record, "projectName", ""))}
                >
                  {get(record, "projectNumber", "")}
                </TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <Tooltip title={get(record, "projectName", "")} placement="top">
                  <TitleTypography>{get(record, "projectName", "")}</TitleTypography>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{get(record, "vicePresident", "")}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{get(record, "projectManager", "")}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{get(record, "projectStatus", "")}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <Grid display={"inline-flex"}>
                  <IconButton
                    onClick={() => handleEditProject(get(record, "id", ""), get(record, "projectName", ""))}
                    sx={{
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                  >
                    <Tooltip title={T.EDIT} placement="top">
                      <Edit />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => handleOpen(get(record, "id", ""))}
                    sx={{
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                  >
                    <Tooltip title={T.REVOKE} placement="top">
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
      <ConfirmSubmit
        proceedButtonText={T.DELETE}
        cancelButtonText={T.CANCEL}
        dialogMessageText={T.CONFIRM_DELETE}
        openConfirmSubmit={isConfirmSubmitOpen}
        handleSubmit={() => handleSubmit()}
        handleClose={() => handleCloseConfirmSubmit()}
      />
    </>
  );
};
MISTableBody.propTypes = {
  allTableRows: PropTypes.array,
  deleteProjectOnId: PropTypes.func,
};
export default MISTableBody;
