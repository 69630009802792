import { useEffect, useReducer } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Paper, styled, Switch, Tab, Tooltip, Typography } from "@mui/material";
import Constants from "Constants";
import { PAGINATION } from "settings/constants/pagination";
import T from "T";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import TopBar from "./TopBar";
import HardwareDashboardTab from "./HardwareDashboard/HardwareDashboardTab";
import HardwareTab from "./HardwareDetail/HardwareTab";
import HardwareHistoryTab from "./HardwareHistory/HardwareHistoryTab";
import { useGetFilterHardwareMutation } from "api/Hardware/getFilterHardware";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { handleError } from "utils/error";
import { shallowEqual, useSelector } from "react-redux";
import { assign, get, isEqual } from "lodash";
import { useDispatch } from "react-redux";
import { hardwareFilterStore } from "slices/hardwareFilterSlice";
import HardwareGrid from "./HardwareDetail/HardwareGrid";
import MISLoader from "components/common/MISLoader";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { format, isValid } from "date-fns";
import { groupBy, mapValues } from "lodash";
import { useGetFilterAssignHardwareMutation } from "api/Hardware/getFilterAssignHardware";
import AssignHardware from "components/Members/Table/AssignHardware";
import { MISCurrentUser } from "utils/validations";
import { downloadFile } from "utils/file";
import { toast } from "react-toastify";
import { useBulkHardwareUploadMutation } from "api/Hardware/bulkHardware";
import { useRevokeHardwareMutation } from "api/Hardware/revokeHardware";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";
import { useLazyViewHardwareQuery } from "api/Hardware/viewHardware";
const { SERVER_URL } = Constants;
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const FULL_ROWS_PER_PAGE = 10000;

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: "14px",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme.palette.background.black,
  minHeight: "unset",
  border: `1px solid ${theme.palette.border.tabsGrey}`,
}));

const StyledSwitch = styled((props) => <Switch {...props} />)(({ theme, checked }) => ({
  padding: 8,
  "& .MuiSwitch-switchBase .MuiSwitch-input": {
    left: "0%",
    width: "300%",
  },
  "& .MuiSwitch-track": {
    borderRadius: 50 / 2,
    "&::before, &::after": {
      position: "absolute",
      top: "51%",
      transform: "translateY(-50%)",
    },
    "&::before": {
      content: checked ? '"Assigned"' : '""',
      left: 20,
      color: theme.palette.background.paper,
    },
    "&::after": {
      content: checked ? '""' : '"Unassigned"',
      right: 20,
      color: theme.palette.background.paper,
    },
  },
  "& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: `${NETSMARTZ_THEME_COLOR} !important`,
    opacity: "1 !important",
  },
  "& .MuiSwitch-thumb": {
    color: BACKGROUND.white,
    boxShadow: "none",
    width: 50 / 2,
    height: 50 / 2,
    margin: 4,
    transform: checked ? "translateX(310%)" : "translateX(0%)",
  },
}));

const Hardware = () => {
  const dispatch = useDispatch();
  const { sessionToken } = MISCurrentUser();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    tabValue: "1",
    searchInput: "",
    filters: {
      hardwareType: null,
      manufacturer: null,
      warrantyEndDate: null,
      warrantyStartDate: null,
      purchaseStartDate: null,
      purchaseEndDate: null,
      workLocation: null,
      status: null,
    },
    historyStartDate: null,
    historyEndDate: null,
    assignStatus: true,
    assignHardware: false,
    AssignHardwareTableData: { allTableRows: [], totalTableRowsCount: null, totalPageCount: null },
    HardwareTableData: { allTableRows: [], totalTableRowsCount: null, totalPageCount: null },
    HardwareViewData: {},
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    bulkHardware: "",
    bulkHardwareFile: null,
    openConfirmRevoke: false,
    revokeId: null,
    exportLoading: false,
  });
  const {
    tabValue,
    searchInput,
    filters,
    historyStartDate,
    historyEndDate,
    assignStatus,
    HardwareTableData,
    AssignHardwareTableData,
    HardwareViewData,
    assignHardware,
    page,
    rowsPerPage,
    bulkHardware,
    bulkHardwareFile,
    openConfirmRevoke,
    revokeId,
    exportLoading,
  } = localState;

  const [getFilterHardware, { isLoading: isFetchingHardware }] = useGetFilterHardwareMutation();
  const [getFilterAssignHardware, { isLoading: isFetchingAssignHardware }] = useGetFilterAssignHardwareMutation();
  const [bulkHardwareUpload] = useBulkHardwareUploadMutation();
  const [revokeHardware] = useRevokeHardwareMutation();
  const [viewHardware] = useLazyViewHardwareQuery();

  const emptyFilters = {
    hardwareType: null,
    manufacturer: null,
    warrantyEndDate: null,
    warrantyStartDate: null,
    purchaseStartDate: null,
    purchaseEndDate: null,
    workLocation: null,
    status: null,
  };
  const { storedFilters } = useSelector(
    (state) => ({
      storedFilters: get(state, "HardwareFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );

  const { storedSearch } = useSelector(
    (state) => ({
      storedSearch: get(state, "HardwareFilterSlice.storedSearch", ""),
    }),
    shallowEqual,
  );

  const { storedStatus } = useSelector(
    (state) => ({
      storedStatus: get(state, "HardwareFilterSlice.storedStatus", false),
    }),
    shallowEqual,
  );

  const { storedDate } = useSelector(
    (state) => ({
      storedDate: get(state, "HardwareFilterSlice.storedDate", ""),
    }),
    shallowEqual,
  );

  const isFilterEmpty = isEqual(storedFilters, emptyFilters);
  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  useEffect(() => {
    setLocalState({
      searchInput: storedSearch,
      assignStatus: storedStatus,
      filters: storedFilters,
      historyStartDate: storedDate.startDate,
      historyEndDate: storedDate.endDate,
    });
    refreshTable(page, rowsPerPage, storedSearch, storedFilters, storedStatus, historyStartDate, historyEndDate);
  }, [page, rowsPerPage, tabValue]);

  useEffect(() => {
    // Check if both historyStartDate and historyEndDate are defined and are not null
    // if (historyStartDate !== null || historyEndDate !== null) {
    //effect will run when startDate or endDate change
    refreshTable(page, rowsPerPage, storedSearch, storedFilters, storedStatus, historyStartDate, historyEndDate);
    dispatch(
      hardwareFilterStore({
        storedSearch: storedSearch,
        storedFilters: storedFilters,
        storedDate: { startDate: historyStartDate, endDate: historyEndDate },
        storedStatus: storedStatus,
      }),
    );
    // }
  }, [historyStartDate, historyEndDate, page, rowsPerPage]);

  // ##################### Refresh Function Calls #######################
  const refreshTable = (page, rowsPerPage, search, filters, status, start, end) => {
    if (tabValue === "2") {
      getHardwareListTableData(page, rowsPerPage, search, filters, status, start, end);
    }
    if (tabValue === "3") {
      getAssignHardwareListTableData(page, rowsPerPage, search, filters, status, start, end);
    }
    if (tabValue === "1") {
      getHardwareView();
    }
  };

  // ############################ API Calls ##############################
  const handleExport = async () => {
    setLocalState({ exportLoading: true });
    const commonFilters = {
      hardwareType: get(filters, "hardwareType.type", ""),
      manufacturer: get(filters, "manufacturer.name", ""),
      workLocation: get(filters, "workLocation.workLocationName", ""),
      text: searchInput,
    };
    const tabSpecificFilters =
      tabValue === "2"
        ? {
          warrantyStartDate: get(filters, "warrantyStartDate", ""),
          warrantyEndDate: get(filters, "warrantyEndDate", ""),
          purchaseStartDate: get(filters, "purchaseStartDate", ""),
          purchaseEndDate: get(filters, "purchaseEndDate", ""),
          hardwareStatus: assignStatus ? "Assignment" : "Unassignment",
        }
        : tabValue === "3"
          ? {
            returnStartDate: get(filters, "warrantyStartDate", ""),
            returnEndDate: get(filters, "warrantyEndDate", ""),
            assignStartDate: historyStartDate,
            assignEndDate: historyEndDate,
            hardwareStatus: get(filters, "status", ""),
          }
          : {};
    const requestDto = { ...commonFilters, ...tabSpecificFilters };

    fetch(`${SERVER_URL}${tabValue === "2" ? "/hardware/export" : "/hardware/export/assignedHardware"}`, {
      method: "POST",
      body: JSON.stringify(requestDto),
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "content-type": "application/json",
      },
    })
      .then((res) => res.blob())
      .then((response) => {
        downloadFile(response, T.CSV);
        setLocalState({ exportLoading: false });
      })
      .catch(handleError);
  };
  const getHardwareListTableData = (page, rowsPerPage, search, filters, status, start, end) => {
    const hardwareFilterRequestDto = {
      hardwareType: get(filters, "hardwareType.type", ""),
      manufacturer: get(filters, "manufacturer.name", ""),
      workLocation: get(filters, "workLocation.workLocationName", ""),
      warrantyStartDate: get(filters, "warrantyStartDate", ""),
      warrantyEndDate: get(filters, "warrantyEndDate", ""),
      purchaseStartDate: get(filters, "purchaseStartDate", ""),
      purchaseEndDate: get(filters, "purchaseEndDate", ""),
      text: search,
      hardwareStatus: status ? "Assignment" : "Unassignment",
    };
    getFilterHardware({ page, rowsPerPage, payload: hardwareFilterRequestDto })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({
          HardwareTableData: {
            allTableRows,
            totalTableRowsCount,
            totalPageCount,
          },
        });
      })
      .catch(handleError);
  };

  const getAssignHardwareListTableData = (page, rowsPerPage, search, filters, status, start, end) => {
    const assignHardwareFilterRequestDto = {
      hardwareType: get(filters, "hardwareType.type", ""),
      manufacturer: get(filters, "manufacturer.name", ""),
      workLocation: get(filters, "workLocation.workLocationName", ""),
      returnStartDate: get(filters, "warrantyStartDate", ""),
      returnEndDate: get(filters, "warrantyEndDate", ""),
      assignStartDate: start,
      assignEndDate: end,
      text: search,
      hardwareStatus: get(filters, "status", ""),
    };
    getFilterAssignHardware({ page, rowsPerPage, payload: assignHardwareFilterRequestDto })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({
          AssignHardwareTableData: {
            allTableRows,
            totalTableRowsCount,
            totalPageCount,
          },
        });
      })
      .catch(handleError);
  };

  const getHardwareView = () => {
    viewHardware()
      .unwrap()
      .then((res) => {
        setLocalState({ HardwareViewData: res });
      })
      .catch(handleError);
  };
  // ############################ Pagination Things ##############################
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };
  // ############################ Search Things ###################################
  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;
    const searchValue = value || get(dataset, "val", "");
    setLocalState({
      showOptions: event.key !== "Enter",
      showCancelIcon: searchValue !== "",
      searchInput: searchValue,
    });
    if (event.key === "Enter" || event.currentTarget.nodeName === "svg" || searchValue === "") {
      dispatch(
        hardwareFilterStore({
          storedSearch: "",
          storedFilters: storedFilters,
          storedStatus: storedStatus,
          storedDate: storedDate,
        }),
      );
      refreshTable(page, rowsPerPage, searchValue, storedFilters, storedStatus, storedDate.startDate, storedDate.endDate);
    }
  };

  const handleSearchKeyChange = () => {
    setLocalState({ page: INITIAL_PAGE });
    dispatch(hardwareFilterStore({ storedSearch: searchInput, storedFilters: storedFilters, storedStatus: storedStatus }));
    refreshTable(INITIAL_PAGE, rowsPerPage, searchInput, filters, storedStatus);
  };
  // ############################ Date Filter Things ###################################
  const onHandleDateChange = (newValue, type) => {
    const validDate = newValue ? new Date(newValue) : null;
    setLocalState({
      [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null,
    });
    // refreshTable(page, rowsPerPage, storedSearch, storedFilters, historyStartDate, historyEndDate);    //calls whenever any one date change
  };
  // #################### Switch and Tab Change Things ############################
  const handleAssignSwitch = () => {
    dispatch(
      hardwareFilterStore({
        storedSearch: "",
        storedFilters: emptyFilters,
        storedStatus: !assignStatus,
        storedDate: { startDate: null, endDate: null },
      }),
    );
    setLocalState({
      assignStatus: !assignStatus,
      page: INITIAL_PAGE,
      searchInput: "",
      historyStartDate: null,
      historyEndDate: null,
      filters: emptyFilters,
      rowsPerPage: ROWS_PER_PAGE,
    });
    refreshTable(page, rowsPerPage, "", emptyFilters, !assignStatus, null, null);
  };

  const handleTabChange = (event, newValue) => {
    dispatch(
      hardwareFilterStore({
        storedSearch: "",
        storedFilters: emptyFilters,
        storedStatus: !assignStatus,
        storedDate: { startDate: null, endDate: null },
      }),
    );
    setLocalState({
      tabValue: newValue,
      assignStatus: !assignStatus,
      page: INITIAL_PAGE,
      searchInput: "",
      historyStartDate: null,
      historyEndDate: null,
      filters: emptyFilters,
      rowsPerPage: ROWS_PER_PAGE,
    });
    if (!(storedDate || storedFilters || storedSearch || storedStatus)) {
      refreshTable(page, rowsPerPage, "", emptyFilters, !assignStatus, null, null);
    }
  };

  // #################### Filters Change Things ############################
  const onhandleFilterChange = (newValue, item) => {
    setLocalState({ filters: { ...filters, [item]: newValue } });
  };

  const onHandleDateFilterChange = (newValue, type) => {
    const validDate = newValue ? new Date(newValue) : null;
    setLocalState({ filters: { ...filters, [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null } });
  };

  const handleFilterSubmit = () => {
    dispatch(
      hardwareFilterStore({
        storedSearch: storedSearch,
        storedFilters: filters,
        storedDate: storedDate,
        storedStatus: storedStatus,
      }),
    );
    refreshTable(page, rowsPerPage, storedSearch, filters, storedStatus, storedDate.startDate, storedDate.endDate);
  };

  const handleFilterClose = () => {
    dispatch(
      hardwareFilterStore({
        storedSearch: storedSearch,
        storedFilters: emptyFilters,
        storedStatus: storedStatus,
        storedDate: { startDate: null, endDate: null },
      }),
    );
    setLocalState({ filters: emptyFilters, historyStartDate: null, historyEndDate: null });
    refreshTable(page, rowsPerPage, storedSearch, emptyFilters, storedStatus, null, null);
  };
  // #################### Bulk Upload File Tool ############################
  const getBinaryFile = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const formData = new FormData();
    formData.append("file", file);
    return { fileName, formData };
  };

  const handleBulkFileUpload = async (event) => {
    const { name } = event.target;
    const { fileName, formData } = await getBinaryFile(event);
    const parts = fileName.split(".");
    const extension = parts[parts.length - 1];
    if (extension !== "XLSX" && extension !== "xlsx") {
      toast.error("Please upload a XLSX file.");
      event.target.value = null;
      return;
    }
    if (!formData) {
      toast.error("File is empty");
      event.target.value = null;
      return;
    }
    setLocalState({ [name]: fileName, bulkHardwareFile: formData });
  };

  const handleDeleteFile = (event, type) => {
    setLocalState({ [type]: "", bulkHardwareFile: "" });
  };

  const handleBulkUploadClose = (type) => {
    setLocalState({ [type]: "", bulkHardwareFile: "" });
  };

  const handleSubmitUpload = (fileType) => {
    bulkHardwareUpload(bulkHardwareFile)
      .unwrap()
      .then((res) => {
        toast.success("Bulk File Uploaded");
        refreshData();
      })
      .catch(handleError);
  };
  // ########################### Helper Functions #################################
  const handleAssignHardwareDialog = () => {
    setLocalState({ assignHardware: !assignHardware });
  };
  const handleCloseHardwareDialog = () => {
    setLocalState({ assignHardware: false });
  };
  const handleConfirmRevoke = (id) => {
    setLocalState({ openConfirmRevoke: !openConfirmRevoke, revokeId: id ? id : null });
  };
  const handleRevokeHardware = () => {
    revokeHardware(revokeId)
      .unwrap()
      .then((res) => {
        toast.success("Hardware Revoked Successfully");
        refreshData();
        handleConfirmRevoke();
      })
      .catch(handleError);
  };
  const refreshData = () => {
    refreshTable(page, rowsPerPage, storedSearch, storedFilters, storedStatus, storedDate.startDate, storedDate.endDate);
  };
  const counts = mapValues(
    groupBy(
      HardwareTableData.allTableRows,
      (item) => `${get(item, "manufacturer.name", "") === "Apple" ? "Apple" : "Windows"}-${get(item, "hardwareType.type", "")}`,
    ),
    (items) => items.length,
  );
  return (
    <Paper display={"block"} sx={{ borderRadius: 2 }}>
      {(isFetchingHardware || isFetchingAssignHardware || exportLoading) && <MISLoader />}
      <TabContext value={tabValue}>
        <TabList
          variant="fullWidth"
          indicatorColor="false"
          onChange={handleTabChange}
          aria-label="lab API tabs example"
          sx={{
            "& .MuiTab-root.Mui-selected": {
              color: BACKGROUND.white,
              backgroundColor: NETSMARTZ_THEME_COLOR,
            },
            "& .MuiTab-root": {
              fontWeight: 600,
            },
          }}
        >
          <StyledTab sx={{ borderTopLeftRadius: "10px" }} label={T.HARDWARE_DASHBOARD.toUpperCase()} value="1" />
          <StyledTab sx={{}} label={T.HARDWARE_DETAIL.toUpperCase()} value="2" />
          <StyledTab sx={{ borderTopRightRadius: "10px" }} label={T.HARDWARE_HISTORY.toUpperCase()} value="3" />
        </TabList>
        {tabValue !== "1" && (
          <TopBar
            status={assignStatus}
            tabValue={tabValue}
            searchInput={searchInput}
            handleChange={handleSearchChange}
            handleKeyChange={handleSearchKeyChange}
            isFilterEmpty={isFilterEmpty}
            filters={filters}
            handleFilterSubmit={handleFilterSubmit}
            onHandleDateFilterChange={onHandleDateFilterChange}
            onhandleFilterChange={onhandleFilterChange}
            handleFilterClose={handleFilterClose}
            historyStartDate={historyStartDate}
            historyEndDate={historyEndDate}
            onHandleDateChange={onHandleDateChange}
            handleAssignHardwareDialog={handleAssignHardwareDialog}
            handleExport={handleExport}
            bulkHardware={bulkHardware}
            handleBulkFileUpload={handleBulkFileUpload}
            handleDeleteFile={handleDeleteFile}
            handleBulkUploadClose={handleBulkUploadClose}
            handleSubmitUpload={handleSubmitUpload}
          />
        )}
        <Box sx={{ "& .MuiTabPanel-root": { p: 2, pt: 0 } }}>
          <TabPanel value="2" sx={{ py: 0, pb: 1 }}>
            <Grid container justifyContent={"space-between"} alignItems={"center"} pb={1}>
              <Grid item container md={2.5} justifyContent={"space-between"}>
                <Typography variant="h5" sx={{ alignContent: "center" }}>
                  {T.HARDWARE}
                </Typography>
                <Grid item xs={12} md={"auto"}>
                  <Tooltip
                    placement="top"
                    title={assignStatus ? T.TOGGLE_TO_SHOW_UNASSIGNED_HARDWARE : T.TOGGLE_TO_SHOW_ASSIGNED_HARDWARE}
                  >
                    <Box>
                      <StyledSwitch
                        sx={{ height: "50px", width: "150px" }}
                        checked={assignStatus}
                        onChange={(e) => handleAssignSwitch()}
                        disableRipple
                      />
                    </Box>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={12} md={"auto"}>
                <HardwareGrid record={counts} />
              </Grid>
            </Grid>
            <HardwareTab
              totalTableRowsCount={HardwareTableData.totalTableRowsCount}
              totalPageCount={HardwareTableData.totalPageCount}
              page={page}
              rowsPerPage={rowsPerPage}
              allTableRows={HardwareTableData.allTableRows}
              assignStatus={assignStatus}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              handleConfirmRevoke={handleConfirmRevoke}
            />
          </TabPanel>
          <TabPanel value="3">
            <HardwareHistoryTab
              totalTableRowsCount={AssignHardwareTableData.totalTableRowsCount}
              totalPageCount={AssignHardwareTableData.totalPageCount}
              page={page}
              rowsPerPage={rowsPerPage}
              allTableRows={AssignHardwareTableData.allTableRows}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TabPanel>
          <TabPanel value="1">
            <HardwareDashboardTab HardwareViewData={HardwareViewData} />
          </TabPanel>
        </Box>
        <AssignHardware
          assignHardware={assignHardware}
          hardwareMod={true}
          handleClose={handleCloseHardwareDialog}
          refreshTable={refreshData}
        />
        <ConfirmSubmit
          proceedButtonText={T.REVOKE}
          openConfirmSubmit={openConfirmRevoke}
          handleClose={() => handleConfirmRevoke()}
          handleSubmit={() => handleRevokeHardware()}
        />
      </TabContext>
    </Paper>
  );
};

export default Hardware;
