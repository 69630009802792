import { useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import ConfirmCancel from "../ConfirmCancel";
import Form from "./Form";

const EditProject = () => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    openConfirmCancel: false,
  });
  const { openConfirmCancel } = localState;

  const navigate = useNavigate();
  const location = useLocation();
  const { projectHeaderName } = location.state || {};

  const handleClose = () => {
    navigate(-1);
  };

  const confrmCancelModal = () => {
    setLocalState({ openConfirmCancel: !openConfirmCancel });
  };
  return (
    <Paper sx={{ p: "16px 16px 0" }}>
      <Box display="flex" alignItems="center" mb={1}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={confrmCancelModal} />
        <Typography variant="h6" fontWeight={700} ml={1}>
          {`${T.EDIT_PROJECT_DETAILS} - ${projectHeaderName}`}
        </Typography>
      </Box>
      <Form handleClose={handleClose} />
      <ConfirmCancel openConfirmCancel={openConfirmCancel} confrmCancelModal={confrmCancelModal} handleClose={handleClose} />
    </Paper>
  );
};

export default EditProject;
