import { Children, useReducer, useEffect } from "react";
import { BACKGROUND, ERROR, NETSMARTZ_THEME_COLOR, SUCCESS } from "theme/colors";
import { SubTitleTypography, TitleTypography } from "utils/members";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Grid,
  Tooltip,
  Paper,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { GET_SIZE } from "utils/responsive";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLazyGetAssignedTrainingQuery } from "api/training/courseAssigned/getCourseAssigned";
import { useSubCourseStartedMutation } from "api/training/courseAssigned/saveSubCourseStarted";
import { useSubCourseCompletedMutation } from "api/training/courseAssigned/saveSubCourseCompleted";
import { useUploadAssignmentFileMutation } from "api/training/courseAssigned/saveCompletionProof";
import T from "T";
import { MISCurrentUser, isUrl, isAsstUrl } from "utils/validations";
import Constants from "Constants";
import { toast } from "react-toastify";
import MISTextField from "components/common/MISTextField";
import WithInputLabel from "components/common/WithInputLabel";
import { PAGINATION } from "settings/constants/pagination";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { turnToArray, capitalizeWords } from "utils/commonFunction";
import { handleError } from "utils/error";
import { completionTypeListing } from "../trainingModal";
import { get } from "utils/lodash";
import { useOfflineTrainingAttendanceMutation } from "api/training/courseAssigned/saveMarkAttendance";
import { ConstructionOutlined } from "@mui/icons-material";
import routes from "router/routes";
import { hover } from "@testing-library/user-event/dist/hover";
import MISLoader from "components/common/MISLoader";
import { fontSize } from "@mui/system";
import { useLazyGetAllTasksQuery } from "api/taskbox/getAllTasks";
import { setBadgeCount } from "slices/taskboxSlice";
import { useDispatch } from "react-redux";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const { SERVER_URL } = Constants;

const StyledAccordion = styled(Accordion)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const TraineeCourseDetail = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const url = pathname;
  const Id = url.includes("traineeCourse") && Number(url.replace(/\D+/g, ""));
  const { user } = MISCurrentUser();
  const [getAllTasks] = useLazyGetAllTasksQuery();
  const dispatch = useDispatch();

  const [subCourseStarted] = useSubCourseStartedMutation();
  const [subCourseCompleted] = useSubCourseCompletedMutation();
  const [uploadAssignmentFile] = useUploadAssignmentFileMutation();
  const [getAssignedTraining] = useLazyGetAssignedTrainingQuery();
  const [offlineTrainingAttendance] = useOfflineTrainingAttendanceMutation();

  const { isLg } = GET_SIZE();
  const { sessionToken } = MISCurrentUser();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    subCoursesProofs: Array.from({ length: state.subsCourses.length }, () => ({
      completionLink: "",
      completionFileName: "",
      completionFile: "",
    })),
    offlineCourse: {
      completionLink: "",
      completionFileName: "",
      completionFile: "",
    },
    Data: state,
    loading: false,
  });
  const { subCoursesProofs, Data, offlineCourse, loading } = localState;

  const getAssignedTrainingTableDataById = (page, size) => {
    getAssignedTraining({ page: page, rowPerPage: size })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({ Data: allTableRows.find((item) => item.id === Id) });
      })
      .catch(handleError);
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getAssignedTrainingTableDataById(INITIAL_PAGE, ROWS_PER_PAGE);
  }, [Id]);

  const onHandleChange = (event, index) => {
    const { name, value } = event.target;
    if (index !== null) {
      subCoursesProofs[index][name] = value;
      setLocalState({ subCoursesProofs });
    } else {
      offlineCourse[name] = value;
      setLocalState({ offlineCourse });
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const formatFileForBE = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const base64 = await convertBase64(file);
    return { fileName, base64 };
  };

  const handleCompletionFile = async (event, index) => {
    const { name } = event.target;
    const { fileName, base64 } = await formatFileForBE(event);
    if (index !== null) {
      subCoursesProofs[index][name] = fileName;
      subCoursesProofs[index]["completionFile"] = base64;
      setLocalState({ subCoursesProofs });
    } else {
      offlineCourse[name] = fileName;
      offlineCourse["completionFile"] = base64;
      setLocalState({ offlineCourse });
    }
  };

  const handleDeleteFile = (event, index) => {
    const { name } = event.target;
    if (index !== null) {
      subCoursesProofs[index]["completionFileName"] = "";
      subCoursesProofs[index]["completionFile"] = "";
      setLocalState({ subCoursesProofs });
    } else {
      offlineCourse["completionFileName"] = "";
      offlineCourse["completionFile"] = "";
      setLocalState({ offlineCourse });
    }
  };

  const handleTrainingAttendance = (id) => {
    offlineTrainingAttendance({ id })
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        getAssignedTrainingTableDataById(INITIAL_PAGE, ROWS_PER_PAGE);
      })
      .catch(handleError);
  };

  const handleCourseStart = (id) => {
    subCourseStarted({ id })
      .unwrap()
      .then(() => {
        toast.success(T.STARTED_SUCCESSFULLY);
        getAssignedTrainingTableDataById(INITIAL_PAGE, ROWS_PER_PAGE);
      })
      .catch(handleError);
  };

  const handleCourseComplete = (id) => {
    subCourseCompleted({ id })
      .unwrap()
      .then(() => {
        getAllTaskbox();
        getAssignedTrainingTableDataById(INITIAL_PAGE, ROWS_PER_PAGE);
        //todo
      })
      .catch(handleError);
  };

  const handleCompletionProof = (id, index) => {
    const { completionLink, completionFileName, completionFile } = index != null ? subCoursesProofs[index] : offlineCourse;
    uploadAssignmentFile({
      id,
      uploadCompletionProofDto: {
        completionProof: completionFile,
        completionProofLink: completionLink,
        fileType: completionFileName,
        trainingAssignmentId: Id,
      },
    })
      .unwrap()
      .then((res) => {
        if (res.status === T.OK) {
          if (!completionLink || isUrl(completionLink)) {
            handleCourseComplete(id);
            toast.success(T.COMPLETED_SUCCESSFULLY);
            // handleBack();
          } else {
            toast.error(T.VALID_URL_LINK);
          }
        } else {
          toast.error(res.message);
        }
      })
      .catch(handleError);
  };

  const getAllTaskbox = () => {
    const payload = {
      isReportingManger: null,
      text: null,
      userEmail: user?.user?.userEmailId ?? null,
    };

    getAllTasks({
      page: INITIAL_PAGE,
      rowPerPage: ROWS_PER_PAGE,
      payload,
    })
      .unwrap()
      .then((res) => {
        if (res) {
          dispatch(setBadgeCount({ badgeCount: get(res, "numberOfRecords", 0) }));
        }
      })
      .catch(handleError);
  };

  const downloadFile = async (url, successMessage) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        toast.success(successMessage);
        window.location.href = responseData.subCourseFileLink;
      } else {
        toast.error("Failed to download the file.");
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleExport = async (Id, type) => {
    try {
      const url =
        type === T.RESOURCE_FILE
          ? `${SERVER_URL}/subCourse/get/subCourseFileLink?resourceFileLinkId=${Id}`
          : `${SERVER_URL}/subCourse/get/subCourseAssignmentFileLink?subCourseId=${Id}`;
      downloadFile(
        url,
        type === T.RESOURCE_FILE ? T.RESOURCE_FILE_DOWNLOADED_SUCCESSFULLY : T.ASSESSMENT_FILE_DOWNLOAD_SUCCESSFULLY,
      );
    } catch (error) {
      handleError(error);
    }
  };

  const handleNetsmartzUniversityLink = async (link) => {
    setLocalState({ loading: true });
    try {
      const userCode = user?.user?.employeeCode.toLowerCase();
      const response = await fetch(
        `http://netsmartzonlineuniversity.com/webservice/rest/server.php?wstoken=944440da6718ebc656d5cf219bd7286f&wsfunction=auth_userkey_request_login_url&moodlewsrestformat=json&user%5Busername%5D=${userCode}`,
      );
      const data = await response.json();
      if (data) {
        const newWind = window.open(data?.loginurl, "_blank");
        if (newWind) {
          setTimeout(() => {
            newWind.close();
            window.location.href = link;
          }, 5000);
        }

      }
    } catch (error) {
      handleError(error);
    } finally {
      setLocalState({ loading: false });
    }
  };

  const { courseDuration, trackStatus, trainingCourse, assignedBy, assignDate, subsCourses, subCoursePriorityStatus } = Data;
  const courseType = get(trainingCourse, "assignmentType.courseAssignmentType", "");
  const offlineMeetingLink = courseType === T.OFFLINE_TRAINING ? get(trainingCourse, "assessmentResourceLink", "") : "";
  return (
    <Paper sx={{ p: 2, height: "calc(100vh - 150px)", overflow: "auto" }}>
      {loading && <MISLoader />}
      <Box display="flex" alignItems="center" mb={1}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack} />
        <Typography variant="h6" fontWeight={700} ml={1}>
          {T.COURSE_DETAIL.toUpperCase()}
        </Typography>
      </Box>

      <Grid spacing={2} container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item mt={2}>
          <Box display="flex">
            <SubTitleTypography>{T.COURSE_NAME}:</SubTitleTypography>
            <TitleTypography ml={1}>{capitalizeWords(trainingCourse.courseName)}</TitleTypography>
          </Box>
          <Box display="flex">
            <SubTitleTypography> {`${T.TRAINING_DURATION} ${T.IN_HRS}`}:</SubTitleTypography>
            <TitleTypography ml={1}>{courseDuration ? courseDuration : ""}</TitleTypography>
          </Box>
        </Grid>
        <Grid item mt={2}>
          <Box display="flex">
            <SubTitleTypography>{T.ASSIGNED_BY}:</SubTitleTypography>
            <TitleTypography ml={1}>{capitalizeWords(assignedBy.name)}</TitleTypography>
          </Box>
          <Box display="flex">
            <SubTitleTypography>{T.ASSIGN_DATE}:</SubTitleTypography>
            <TitleTypography ml={1}>{assignDate}</TitleTypography>
          </Box>
        </Grid>
      </Grid>

      {courseType !== T.OFFLINE_TRAINING ? (
        Children.toArray(
          subsCourses.map((subsCourse, index) => {
            const { subCourse, status, id } = subsCourse;
            const prevStatus = index - 1 === -1 ? status : subsCourses[index - 1].status;
            const assessmentFileLink = subCourse.assessmentFileLink;
            const resourceFileLink = subCourse.resourceLink[0].fileLink;
            const completionType = subCourse.resourceLink[0].completionType;
            const resourceLinks = turnToArray(subCourse.resourceLink[0].resourceLink);
            const training_resource_link_id = subCourse.resourceLink[0].training_resource_link_id;
            const { completionLink, completionFile } = subCoursesProofs[index];
            return (
              <StyledAccordion sx={{ mt: 3 }} variant="outlined">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent={isLg ? "space-between" : "center"}
                    sx={{
                      mt: 1,
                      mb: 1,
                      pl: 3,
                      pr: 3,
                      "& .MuiOutlinedInput-input": {
                        fontSize: 14,
                      },
                    }}
                  >
                    <Grid item md={11} xs={12}>
                      <Typography fontSize={18} fontWeight={600}>
                        {subCourse.subsCourseName}
                      </Typography>
                    </Grid>
                    <Grid item md={1} xs={12}>
                      {status}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper sx={{ p: 1 }}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isLg ? "space-between" : "center"}
                      sx={{
                        mt: 1,
                        mb: 1,
                        pl: 3,
                        pr: 3,
                        "& .MuiOutlinedInput-input": {
                          fontSize: 14,
                        },
                      }}
                    >
                      <Grid item md={12} xs={12}>
                        <Box display="flex">
                          <Typography fontWeight={600}>{T.DESCRIPTION}:</Typography>
                          <Typography ml={1}>{subCourse.description}</Typography>
                        </Box>
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <Box display="flex" sx={{ marginTop: 3 }}>
                          <Typography fontWeight={600}>{T.RESOURCE_LINKS}:</Typography>
                        </Box>
                        {resourceLinks
                          ? resourceLinks.map((link, index) => (
                            <div key={index}>
                              <Box display="flex" whiteSpace="nowrap" sx={{ marginTop: 1 }}>
                                <SubTitleTypography>
                                  {T.LINK}
                                  {` ${index + 1}`}:
                                </SubTitleTypography>
                                <TitleTypography ml={1}>
                                  {
                                    link?.includes("netsmartzonlineuniversity.com") ? (
                                      <span
                                        style={{ cursor: "pointer", color: "blue" }}
                                        onClick={() => handleNetsmartzUniversityLink(link)}
                                      >
                                        {link}
                                      </span>
                                    ) : (
                                      <a href={link} target="_blank" rel="noreferrer">
                                        {link}
                                      </a>
                                    )
                                    //http://netsmartzonlineuniversity.com/webservice/rest/server.php?wstoken=944440da6718ebc656d5cf219bd7286f&wsfunction=auth_userkey_request_login_url&moodlewsrestformat=json&user%5Busername%5D=c0166
                                  }
                                </TitleTypography>
                              </Box>
                            </div>
                          ))
                          : null}
                      </Grid>

                      <Grid item md={2} xs={12}>
                        <Box display="flex" sx={{ marginTop: 1 }}>
                          <Typography fontWeight={600}>{T.RESOURCE_FILE}:</Typography>
                        </Box>
                        <Tooltip title={T.DOWNLOAD} placement="top">
                          <IconButton
                            onClick={() => handleExport(training_resource_link_id, T.RESOURCE_FILE)}
                            disabled={!resourceFileLink}
                            sx={{
                              mr: 1,
                              height: 40,
                              color: NETSMARTZ_THEME_COLOR,
                            }}
                          >
                            <FileDownloadOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <Box display="flex">
                          <Typography fontWeight={600}>{T.ASSESSMENT_LINK}:</Typography>
                        </Box>
                        <Box display="flex" sx={{ marginTop: 1 }}>
                          <SubTitleTypography>{T.LINK}:</SubTitleTypography>
                          <TitleTypography ml={1}>
                            {subCourse?.assessmentResourceLink?.includes("netsmartzonlineuniversity.com") ? (
                              <span
                                style={{ cursor: "pointer", color: "blue" }}
                                onClick={() => handleNetsmartzUniversityLink(subCourse?.assessmentResourceLink)}
                              >
                                {subCourse?.assessmentResourceLink}
                              </span>
                            ) : (
                              <a href={subCourse?.assessmentResourceLink} target="_blank" rel="noreferrer">
                                {subCourse?.assessmentResourceLink}
                              </a>
                            )}
                          </TitleTypography>
                        </Box>
                      </Grid>

                      <Grid item md={2} xs={12}>
                        <Box display="flex">
                          <Typography fontWeight={600}>{T.ASSIGNMENT_FILE}:</Typography>
                        </Box>
                        <Tooltip title={T.DOWNLOAD} placement="top">
                          <IconButton
                            onClick={() => handleExport(subCourse.training_sub_course_id, T.ASSIGNMENT_FILE)}
                            disabled={!assessmentFileLink}
                            sx={{
                              mr: 1,
                              height: 40,
                              cursor: "pointer",
                              color: NETSMARTZ_THEME_COLOR,
                            }}
                          >
                            <FileDownloadOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isLg ? "space-between" : "center"}
                      sx={{
                        mt: 1,
                        mb: 1,
                        pl: 3,
                        pr: 3,
                        "& .MuiOutlinedInput-input": {
                          fontSize: 14,
                        },
                      }}
                    >
                      {[T.COMPLETION_LINK, T.BOTH].includes(
                        completionTypeListing.find((item) => item.completionTypeId === completionType).completionType,
                      ) ? (
                        <Grid item md={4} xs={12}>
                          <MISTextField
                            label={T.COMPLETION_LINK}
                            fullWidth
                            placeholder={T.COMPLETION_LINK}
                            disabled={[T.YET_TO_START, T.COMPLETED, T.REVIEW_PENDING].includes(status)}
                            autoComplete="on"
                            size="small"
                            variant="outlined"
                            name="completionLink"
                            value={subCoursesProofs[index]["completionLink"]}
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderBottom:
                                  subCoursesProofs[index]["completionLink"] &&
                                  `3px solid ${isUrl(subCoursesProofs[index]["completionLink"]) ? SUCCESS.main : ERROR.main}`,
                              },
                            }}
                            onChange={(e) => onHandleChange(e, index)}
                          />
                        </Grid>
                      ) : null}
                      {[T.COMPLETION_FILE, T.BOTH].includes(
                        completionTypeListing.find((item) => item.completionTypeId === completionType).completionType,
                      ) ? (
                        <Grid item md={2} xs={12}>
                          <WithInputLabel label={T.COMPLETION_FILE}>
                            <input
                              name="completionFileName"
                              style={{ color: NETSMARTZ_THEME_COLOR, marginTop: 10 }}
                              type="file"
                              disabled={[T.YET_TO_START, T.COMPLETED, T.REVIEW_PENDING].includes(status)}
                              onChange={(e) => handleCompletionFile(e, index)}
                              onClick={(e) => handleDeleteFile(e, index)}
                            />
                          </WithInputLabel>
                        </Grid>
                      ) : null}
                      <Grid item md={4} xs={12}>
                        {status === T.YET_TO_START ? (
                          <Button
                            variant="contained"
                            sx={{
                              bgcolor: NETSMARTZ_THEME_COLOR,
                              whiteSpace: "nowrap",
                              color: BACKGROUND.white,
                              "& .MuiButton-startIcon": {
                                mr: 0,
                              },
                              "&:hover": {
                                bgcolor: NETSMARTZ_THEME_COLOR,
                                color: BACKGROUND.white,
                              },
                            }}
                            endIcon={<PlayArrowOutlinedIcon sx={{ width: 19 }} />}
                            onClick={() => handleCourseStart(id)}
                            disabled={
                              subCoursePriorityStatus && [T.YET_TO_START, T.IN_PROGRESS].includes(prevStatus) && index !== 0
                            }
                          >
                            {"click here to mark the course as started"}
                          </Button>
                        ) : status === T.REVIEW_PENDING ? null : (
                          <Button
                            variant="contained"
                            sx={{
                              bgcolor: NETSMARTZ_THEME_COLOR,
                              whiteSpace: "nowrap",
                              color: BACKGROUND.white,
                              "& .MuiButton-startIcon": {
                                mr: 0,
                              },
                              "&:hover": {
                                bgcolor: NETSMARTZ_THEME_COLOR,
                                color: BACKGROUND.white,
                              },
                            }}
                            onClick={() => handleCompletionProof(id, index)}
                            disabled={
                              [T.COMPLETION_LINK, T.COMPLETION_FILE].includes(
                                completionTypeListing.find((item) => item.completionTypeId === completionType).completionType,
                              )
                                ? completionLink === "" && completionFile === ""
                                : completionLink === "" || completionFile === ""
                            }
                          >
                            {T.SUBMIT}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </AccordionDetails>
              </StyledAccordion>
            );
          }),
        )
      ) : (
        <>
          <Button
            variant="contained"
            sx={{
              marginTop: "10px",
              bgcolor: NETSMARTZ_THEME_COLOR,
              whiteSpace: "nowrap",
              color: BACKGROUND.white,
              "& .MuiButton-startIcon": {
                mr: 0,
              },
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
                color: BACKGROUND.white,
              },
            }}
            endIcon={<PlayArrowOutlinedIcon sx={{ width: 19 }} />}
            onClick={() => handleTrainingAttendance(Id)}
            disabled={![T.YET_TO_START].includes(trackStatus)}
          >
            {T.MARK_ATTENDANCE}
          </Button>
          {subsCourses.length === 0 && (
            <Grid item md={4} xs={12}>
              <Box display="flex" sx={{ marginTop: 3 }}>
                <Typography fontWeight={600}>{T.MEETING_LINK}:</Typography>
                <TitleTypography ml={1}>
                  <a href={offlineMeetingLink} target="_blank" rel="noreferrer">
                    {offlineMeetingLink}
                  </a>
                </TitleTypography>
              </Box>
            </Grid>
          )}
          {Children.toArray(
            subsCourses.map((subsCourse) => {
              const { subCourse, status, id, completionProofFile, completionProofLink } = subsCourse;
              const { resourceLink, assessmentResourceLink, assessmentFileLink } = subCourse;
              const completionType = resourceLink[0].completionType;
              const { completionLink, completionFile } = offlineCourse;
              return (
                <>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent={isLg ? "space-between" : "center"}
                    sx={{
                      mt: 1,
                      mb: 1,
                      pr: 3,
                      "& .MuiOutlinedInput-input": {
                        fontSize: 14,
                      },
                    }}
                  >
                    <Grid item md={4} xs={12}>
                      <Box display="flex" sx={{ marginTop: 3 }}>
                        <Typography fontWeight={600}>{T.MEETING_LINK}:</Typography>
                        <TitleTypography ml={1}>
                          <a href={offlineMeetingLink} target="_blank" rel="noreferrer">
                            {offlineMeetingLink}
                          </a>
                        </TitleTypography>
                      </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Box display="flex" sx={{ marginTop: 2 }}>
                        <Typography fontWeight={600}>{T.ASSESSMENT_LINK}:</Typography>
                        <TitleTypography ml={1}>
                          <a href={assessmentResourceLink} target="_blank" rel="noreferrer">
                            {assessmentResourceLink}
                          </a>
                        </TitleTypography>
                      </Box>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <Box display="flex" sx={{ marginTop: 3 }}>
                        <Typography fontWeight={600}>{T.ASSIGNMENT_FILE}:</Typography>
                      </Box>
                      <Tooltip title={T.DOWNLOAD} placement="top">
                        <IconButton
                          onClick={() => handleExport(subCourse.training_sub_course_id, T.ASSIGNMENT_FILE)}
                          disabled={!assessmentFileLink}
                          sx={{
                            mr: 1,
                            height: 40,
                            cursor: "pointer",
                            color: NETSMARTZ_THEME_COLOR,
                          }}
                        >
                          <FileDownloadOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent={isLg ? "space-between" : "center"}
                    sx={{
                      mt: 1,
                      mb: 1,
                      pr: 3,
                      "& .MuiOutlinedInput-input": {
                        fontSize: 14,
                      },
                    }}
                  >
                    {[T.COMPLETION_LINK, T.BOTH].includes(
                      completionTypeListing.find((item) => item.completionTypeId === completionType).completionType,
                    ) ? (
                      <Grid item md={4} xs={12}>
                        <MISTextField
                          label={T.COMPLETION_LINK}
                          fullWidth
                          placeholder={T.COMPLETION_LINK}
                          disabled={[T.YET_TO_START, T.ATTENDED, T.PARTIALLY_ATTENDED, T.ABSENT].includes(trackStatus)}
                          autoComplete="on"
                          size="small"
                          variant="outlined"
                          name="completionLink"
                          value={offlineCourse["completionLink"]}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderBottom:
                                offlineCourse["completionLink"] &&
                                `3px solid ${isUrl(offlineCourse["completionLink"]) ? SUCCESS.main : ERROR.main}`,
                            },
                          }}
                          onChange={(e) => onHandleChange(e, null)}
                        />
                      </Grid>
                    ) : null}
                    {[T.COMPLETION_FILE, T.BOTH].includes(
                      completionTypeListing.find((item) => item.completionTypeId === completionType).completionType,
                    ) ? (
                      <Grid item md={2} xs={12}>
                        <WithInputLabel label={T.COMPLETION_FILE}>
                          <input
                            name="completionFileName"
                            style={{ color: NETSMARTZ_THEME_COLOR, marginTop: 10 }}
                            type="file"
                            disabled={[T.YET_TO_START, T.ATTENDED, T.PARTIALLY_ATTENDED, T.ABSENT].includes(trackStatus)}
                            onChange={(e) => handleCompletionFile(e, null)}
                            onClick={(e) => handleDeleteFile(e, null)}
                          />
                        </WithInputLabel>
                      </Grid>
                    ) : null}
                    {![T.ATTENDED_REVIEW_PENDING, T.REVIEW_PENDING].includes(trackStatus) ? null : (
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: NETSMARTZ_THEME_COLOR,
                          whiteSpace: "nowrap",
                          color: BACKGROUND.white,
                          "& .MuiButton-startIcon": {
                            mr: 0,
                          },
                          "&:hover": {
                            bgcolor: NETSMARTZ_THEME_COLOR,
                            color: BACKGROUND.white,
                          },
                        }}
                        onClick={() => handleCompletionProof(id, null)}
                        disabled={
                          [T.COMPLETION_LINK, T.COMPLETION_FILE].includes(
                            completionTypeListing.find((item) => item.completionTypeId === completionType).completionType,
                          )
                            ? completionLink === "" && completionFile === ""
                            : completionLink === "" || completionFile === ""
                        }
                      >
                        {T.SUBMIT}
                      </Button>
                    )}
                  </Grid>
                </>
              );
            }),
          )}
        </>
      )}
    </Paper>
  );
};

export default TraineeCourseDetail;
