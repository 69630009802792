import { useRef } from "react";
import { CategoryScale, LinearScale, BarElement, BarController } from "chart.js";
import { registerChartComponents } from "utils/chartRegister";
import useChart from "hooks/useChart";
import chartjsPluginDatalabels from "chartjs-plugin-datalabels";

const totalOnTop = {
  id: 'totalOnTop',
  afterDatasetsDraw(chart) {
    const { ctx, scales } = chart;
    const { x = { getPixelForValue: () => 0 }, y = { getPixelForValue: () => 0 } } = scales;

    chart.data.labels.forEach((_, index) => {
      const datasetArray = [];
      chart.data.datasets.forEach((dataset, datasetIndex) => {
        if (chart.isDatasetVisible(datasetIndex)) {
          datasetArray.push(dataset.data[index] || 0);
        }
      });
      const sum = datasetArray.reduce((a, c) => a + c, 0);
      if (sum > 0) {
        ctx.save();
        ctx.font = 'bold 12px sans-serif';
        ctx.fillStyle = "#000000";
        ctx.textAlign = 'center';
        ctx.fillText(sum, x.getPixelForValue(index), y.getPixelForValue(sum) - 10);
        ctx.restore();
      }
    });
  }
};
const paddingBetweenLegends = {
  id: 'paddingBetweenLegends',
  beforeInit(chart) {
    const legend = chart.legend;
    const originalFit = legend.fit;
    legend.fit = () => {
      originalFit.call(legend);
      legend.height += 20;
    };
  }
}
registerChartComponents([CategoryScale, LinearScale, BarController, BarElement, chartjsPluginDatalabels, totalOnTop, paddingBetweenLegends]);

const StackedBar = ({ categoryCounts = {}, brandInfo = {} }) => {
  const canvasRef = useRef(null);
  const labels = Object.keys(categoryCounts);
  const assignedData = labels.map((label) => categoryCounts[label].Assigned);
  const unassignedData = labels.map((label) => categoryCounts[label].Unassigned);

  const chartConfig = {
    type: "bar",
    data: {
      labels: labels,
      datasets: [
        {
          label: "Assigned",
          data: assignedData,
          backgroundColor: "rgba(255, 99, 132, 0.7)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
        {
          label: "Unassigned",
          data: unassignedData,
          backgroundColor: "rgba(54, 162, 235, 0.7)",
          borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top", labels: {
            usePointStyle: true,
          },
        },
        title: { display: true, text: "Hardware Items" },
        datalabels: {
          display: (context) => context.dataset.data[context.dataIndex] !== 0,
          align: "center",
          anchor: "center",
          color: "black",
          font: { size: 14 },
        },
        tooltip: {
          callbacks: {
            afterBody: (tooltipItems) => {
              const datasetLabel = tooltipItems[0].dataset.label;
              const category = tooltipItems[0].label;
              const brands = brandInfo[category];
              return Object.entries(brands)
                .map(([brand, counts]) => {
                  return datasetLabel === "Assigned" ? `${brand}: ${counts.Assigned}` : `${brand}: ${counts.Unassigned}`;
                })
                .join("\n");
            },
          },
        },
      },
      scales: { x: { grid: { display: false }, stacked: true }, y: { grid: { display: false }, stacked: true } },
    },
  };

  useChart(canvasRef, chartConfig);
  return <canvas ref={canvasRef}></canvas>;
};

export default StackedBar;
