import T from "T";
export const HARDWARE_DETAIL_TABLE_HEADER = [
  { label: T.HARDWARE_TYPE },
  { label: T.MANUFACTURER },
  { label: T.MODEL },
  { label: T.SERIAL_NUMBER },
  { label: T.PURCHASE_DATE },
  { label: T.WARRANTY_END_DATE },
  { label: T.WORK_LOCATION },
  { label: T.ACTION_BUTTONS },
];

export const HARDWARE_HISTORY_TABLE_HEADER = [
  { label: T.HARDWARE_DETAIL },
  { label: T.PERSONAL_INFO },
  { label: T.ASSIGNED_DATE },
  { label: T.RETURN_DATE },
];

export const SORTING_KEYS = [
  { key: "purchaseDate", value: "purchaseDate" },
  { key: "warrantyEndDate", value: "warrantyEndDate" },
  { key: "hardwareType", value: "hardwareType.type" },
];

export const ASSING_SORTING_KEY = [
  { key: "personalInfo", value: "userName" },
  { key: "assignedDate", value: "assignedDate" },
];
