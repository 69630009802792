import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { useState } from "react";
import { TableRow, TableCell, Typography, TableBody, styled, Button, Tooltip, Grid, Box } from "@mui/material";

import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import T from "T";
import RecordingListDialog from "../Dialogs/RecordingListDialog";
import MISButton from "components/common/MISButton";
import { useNavigate } from "react-router-dom";
import { ERROR, SUCCESS } from "theme/colors";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));
const CandidateRecordTableBody = ({
  allTableRows = [],
  page,
  rowsPerPage,
  handleRecordingDialog = noop,
  handleScreenshotDialog = noop,
  refreshTable = noop,
}) => {
  const navigate = useNavigate();
  const handleAssessmentClick = (id, mockResponses, techResponse) => {
    navigate(`/app/assessment-evaluation/${id}`, { state: { mockResponses, techResponse } });
  };

  const getAverageMockLevelColor = (averageMockLevel) => {
    const scoreValue = parseFloat(averageMockLevel); // Parse average mock level to a number

    if (scoreValue >= 0 && scoreValue < 3) {
      return ERROR.main; // Red for levels between 1 and 2.99
    } else if (scoreValue >= 3 && scoreValue < 4) {
      return NETSMARTZ_THEME_COLOR; // Yellow for level between 3 and 3.99
    } else if (scoreValue >= 4 && scoreValue <= 5) {
      return SUCCESS.main; // Green for levels 4 and 5
    }
    return "inherit"; // Default color
  };

  const getTechScoreColor = (score) => {
    const scoreValue = parseInt(score, 10); // Parse score to integer

    if (scoreValue < 3) {
      return ERROR.main; // Red for low scores
    } else if (scoreValue >= 3 && scoreValue < 6) {
      return NETSMARTZ_THEME_COLOR; // Yellow for medium scores
    } else if (scoreValue >= 6) {
      return SUCCESS.main; // Green for high scores
    }
    return "inherit"; // Default color
  };

  return (
    <>
      <TableBody>
        {Children.toArray(
          allTableRows.map((record, index) => {
            const videoInterviewQAResponseDtoList = get(record, "videoInterviewQAResponseDtoList", []);
            const interviewScreenshotResponseDtoList = get(record, "interviewScreenshotResponseDtoList", []);
            const videoInterviewMockResponseDtos = get(record, "videoInterviewMockResponseDtos", []);
            const videoInterviewTechResponseDto = get(record, "videoInterviewTechResponseDto", {});
            const questionEngagementScore = get(record, "questionEngagementScore", 0);
            const logicalLevelScore = get(record, "logicalLevelScore", 0);
            const mockAverage = get(record, "mockAverage", 0);
            return (
              <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
                <StyledTableCell>{index + 1 + page * rowsPerPage}.</StyledTableCell>
                <StyledTableCell>{get(record, "name", "")}</StyledTableCell>
                <StyledTableCell>{get(record, "email", "")}</StyledTableCell>
                <StyledTableCell>{get(record, "collegeName", "")}</StyledTableCell>
                <StyledTableCell>{get(record, "stream", "")}</StyledTableCell>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  <p style={{ color: getAverageMockLevelColor(mockAverage) }}>{mockAverage}</p>
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  {" "}
                  <p style={{ color: getTechScoreColor(questionEngagementScore) }}>{questionEngagementScore}</p>
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  {" "}
                  <p style={{ color: getTechScoreColor(logicalLevelScore) }}>{logicalLevelScore}</p>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: "flex", justifyContent: "space-between", paddingX: 1 }}>
                    <MISButton
                      variant="contained"
                      size="small"
                      // onClick={() => handleRejectCaseStudyDialog(get(record, "id", ""), "accept")}
                      onClick={() =>
                        handleAssessmentClick(record.id, videoInterviewMockResponseDtos, videoInterviewTechResponseDto)
                      }
                      sx={{
                        bgcolor: NETSMARTZ_THEME_COLOR,
                        color: "white",
                        "&:hover": {
                          background: NETSMARTZ_THEME_COLOR,
                        },
                      }}
                      // disabled={get(record, "caseStudyStatus", "") !== T.SUBMITTED_BY_MT}
                    >
                      {T.ASSESSMENT}
                    </MISButton>
                    <MISButton
                      variant="contained"
                      size="small"
                      onClick={() => handleRecordingDialog(record.name, videoInterviewQAResponseDtoList)}
                      sx={{
                        ml: 1,
                        bgcolor: NETSMARTZ_THEME_COLOR,
                        color: "white",
                        "&:hover": {
                          background: NETSMARTZ_THEME_COLOR,
                        },
                      }}
                      // disabled={get(record, "caseStudyStatus", "") !== T.SUBMITTED_BY_MT}
                    >
                      {T.RECORDINGS}
                    </MISButton>
                    <MISButton
                      variant="contained"
                      size="small"
                      onClick={() => handleScreenshotDialog(record.name, interviewScreenshotResponseDtoList)}
                      sx={{
                        ml: 1,
                        bgcolor: NETSMARTZ_THEME_COLOR,
                        color: "white",
                        "&:hover": {
                          background: NETSMARTZ_THEME_COLOR,
                        },
                      }}
                      // disabled={get(record, "caseStudyStatus", "") !== T.SUBMITTED_BY_MT}
                    >
                      {T.SCREENSHOT}
                    </MISButton>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            );
          }),
        )}
      </TableBody>
    </>
  );
};

CandidateRecordTableBody.propTypes = {
  allTableRows: PropTypes.array,
  pName: PropTypes.string,
  refreshTable: PropTypes.func,
  handleRejectCaseStudyDialog: PropTypes.func,
  handleCaseStudyUploadLinkDialog: PropTypes.func,
  onClickReminder: PropTypes.func,
};

export default memo(CandidateRecordTableBody);
