import { CheckBox } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { orange } from "@mui/material/colors";
import { Box } from "@mui/system";
import MISDialog from "components/common/MISDialog";
import React, { useEffect, useState } from "react";
import { Checkbox, FormGroup } from "@mui/material";
import MISFooterButton from "components/common/MISFooterButton";
import T from "T";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import MISTextField from "components/common/MISTextField";
import { useLazyGetFeedbackTrainingQuery } from "api/training/feedback/getFeedbackTraining";
import { handleError } from "utils/error";
import { get, noop } from "lodash";
import { useSaveFeedbackTrainingMutation } from "api/training/feedback/saveFeedbackTraining";
import { toast } from "react-toastify";

const questionType = {
  radio: "RADIO",
  checkbox: "CHECKBOX",
  text: "FILL_IN_THE_BLANK",
  multipleChoice: "MULTIPLE_CHOICE",
};

const FeedbackForm = ({
  showDialog = true,
  handleShowDialog = noop,
  feedback = null,
  refreshTable = noop,
  handleEmptyTask = noop,
}) => {
  const [getTrainingFeedback] = useLazyGetFeedbackTrainingQuery();
  const [saveFeedbackTraining] = useSaveFeedbackTrainingMutation();
  const [feedBackForm, setFeedBackForm] = useState({
    feedbackId: 0,
    feedbackName: "Feedback Form",
    description: "",
    createdAt: "",
    responseId: "",
    questions: [
      {
        questionId: 0,
        questionText: "",
        questionType: "",
        options: [
          {
            optionId: 0,
            optionText: "",
          },
        ],
        createdAt: "",
        selectedValues: [],
      },
    ],
  });

  useEffect(() => {
    if (feedback) {
      const questionsRes = get(feedback, "feedback.questions", []);
      setFeedBackForm((prevForm) => ({
        ...prevForm,
        feedbackId: get(feedback, "feedback.feedbackId", ""),
        feedbackName: get(feedback, "feedback.feedbackName", ""),
        description: get(feedback, "feedback.description", ""),
        createdAt: get(feedback, "feedback.createdAt", ""),
        responseId: get(feedback, "responseId", ""),
        questions: questionsRes?.map((it) => ({
          questionId: get(it, "questionId", 0),
          questionText: get(it, "questionText", ""),
          questionType: get(it, "questionType", ""),
          options: get(it, "options", [])?.map((option) => ({
            optionId: get(option, "optionId", 0),
            optionText: get(option, "optionText", ""),
          })),
          createdAt: get(it, "createdAt", ""),
          selectedValues: [],
        })),
      }));
    }
  }, [feedback]);

  const handleRadioChange = (questionId, value) => {
    setFeedBackForm((prevForm) => ({
      ...prevForm,
      questions: prevForm.questions.map((question) =>
        question.questionId === questionId ? { ...question, selectedValues: [value] } : question,
      ),
    }));
  };

  const handleCheckboxChange = (questionId, value) => {
    setFeedBackForm((prevForm) => ({
      ...prevForm,
      questions: prevForm.questions.map((question) => {
        if (question.questionId === questionId) {
          const selectedValues = question.selectedValues.includes(value)
            ? question.selectedValues.filter((v) => v !== value)
            : [...question.selectedValues, value];
          return { ...question, selectedValues };
        }
        return question;
      }),
    }));
  };

  const handleTextChange = (questionId, value) => {
    setFeedBackForm((prevForm) => ({
      ...prevForm,
      questions: prevForm.questions.map((question) =>
        question.questionId === questionId ? { ...question, selectedValues: [value] } : question,
      ),
    }));
  };

  const disableSubmit = () => {
    const selections = feedBackForm?.questions;
    const disable = selections.some((it) => it.selectedValues.length < 1);
    if (disable) {
      toast.error("Please fill all questions");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!disableSubmit()) {
      return;
    }
    const payload = {
      feedbackId: feedBackForm?.feedbackId,
      responseId: feedBackForm.responseId,
      responses: {
        ...feedBackForm.questions.reduce((acc, question) => {
          const value =
            question.selectedValues.length > 0
              ? question.questionType === questionType.multipleChoice || question.questionType === questionType.checkbox
                ? question.selectedValues
                : question.selectedValues[0]
              : null; // Assign null if no selected values
          acc[question.questionId] = value;
          return acc;
        }, {}),
      },
    };
    saveFeedbackTraining({ payload })
      .unwrap()
      .then((res) => {
        if (res) {
          toast.success("Feedback completed successfully");
          refreshTable();
          handleShowDialog();
        }
      })
      .catch(handleError);
  };
  const handleBack = () => {
    handleShowDialog();
    handleEmptyTask();
  };
  return (
    <Dialog open={showDialog} onClose={() => handleBack()} maxWidth="sm" disableEscapeKeyDown>
      <DialogTitle sx={{ textAlign: "center", pb: 0 }}>
        <Typography variant="h5">{feedBackForm?.feedbackName}</Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          {feedBackForm?.description}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ height: "65vh", overflowY: "auto", pt: 2 }}>
        <Grid container spacing={2}>
          {feedBackForm.questions?.map((question, index) => (
            <Grid item xs={12} key={question.questionId}>
              <Paper elevation={2} sx={{ p: 2 }}>
                <Typography variant="body1" sx={{ mb: 2, fontWeight: "bold" }}>
                  {index + 1}. {question.questionText}
                </Typography>
                {question.questionType === questionType.radio && (
                  <RadioGroup
                    value={question.selectedValues[0] || ""}
                    onChange={(e) => handleRadioChange(question.questionId, e.target.value)}
                  >
                    {question.options?.map((option) => (
                      <FormControlLabel
                        key={option.optionId}
                        value={option.optionText}
                        control={<Radio style={{ color: NETSMARTZ_THEME_COLOR }} />}
                        label={option.optionText}
                      />
                    ))}
                  </RadioGroup>
                )}
                {(question.questionType === questionType.checkbox || question.questionType === questionType.multipleChoice) && (
                  <FormGroup>
                    {question.options?.map((option) => (
                      <FormControlLabel
                        key={option.optionId}
                        control={
                          <Checkbox
                            checked={question.selectedValues.includes(option.optionText)}
                            onChange={() => handleCheckboxChange(question.questionId, option.optionText)}
                            sx={{
                              "&.Mui-checked": {
                                color: orange[700],
                              },
                            }}
                          />
                        }
                        label={option.optionText}
                      />
                    ))}
                  </FormGroup>
                )}
                {question.questionType === questionType.text && (
                  <MISTextField
                    fullWidth
                    variant="outlined"
                    value={question.selectedValues[0] || ""}
                    onChange={(e) => handleTextChange(question.questionId, e.target.value)}
                    sx={{ marginTop: 2 }}
                  />
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <MISFooterButton proceedButtonText={T.SUBMIT} handleSubmit={handleSubmit} handleClose={() => handleBack()} />
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackForm;
