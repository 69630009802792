import { Children } from "react";
import PropTypes from "prop-types";
import NorthSharpIcon from "@mui/icons-material/NorthSharp";
import { camelCase, noop } from "lodash";
import { TableRow, TableHead, TableCell, Typography, Box } from "@mui/material";
import memo from "utils/react";
import { get } from "utils/lodash";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { SORTING_KEYS } from "../businessCentralModel";

const TableHeader = ({ columns = [], activeSort = "", sortDirection = 0, handleToolsSorting = noop }) => (
  <TableHead>
    <TableRow sx={{ height: 20 }}>
      {Children.toArray(
        columns.map((column, ind) => {
          const columnName = get(column, "label", "").toUpperCase();
          const hasSorting = SORTING_KEYS.map(item => item.key).includes(camelCase(columnName));
          return (
            <TableCell
              sx={{
                border: "none",
                backgroundColor: BACKGROUND.header,
                paddingY: 0,
              }}
            >

              <Box
                sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                onClick={() => handleToolsSorting(camelCase(columnName))}
              >
                <Typography variant="body1" align="center" fontSize={14} noWrap fontWeight={600}>
                  {columnName}
                </Typography>
                {hasSorting ?
                  <NorthSharpIcon
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                      fill: NETSMARTZ_THEME_COLOR,
                      opacity: activeSort === camelCase(columnName) && sortDirection !== 0 ? 1 : 0.3,
                      transition: "all 500ms",
                      transform:
                        activeSort === camelCase(columnName)
                          ? [0, 1].includes(sortDirection)
                            ? "rotateX(0deg)"
                            : "rotateX(180deg)"
                          : "rotateX(0deg)",
                    }}
                  />
                  : null}
              </Box>
            </TableCell>
          );
        }),
      )}
    </TableRow>
  </TableHead>
);

TableHeader.propTypes = {
  columns: PropTypes.array,
};

export default memo(TableHeader);
