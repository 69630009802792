import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    revokeHardware: builder.mutation({
      query: (id) => ({
        url: `/hardware/revoke?hardwareId=${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useRevokeHardwareMutation } = extendedApi;
