import { Children, memo, useReducer, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { get } from "utils/lodash";
import { TableRow, TableCell, Typography, TableBody, styled, Rating, Tooltip, Badge, IconButton, Button } from "@mui/material";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import { MainTitleTypography, SubTitleTypography, TitleTypography } from "utils/members";
import { MISCurrentUser } from "utils/validations";
import { BACKGROUND } from "theme/colors";
import MISButton from "components/common/MISButton";
import { NotificationsOutlined } from "@mui/icons-material";
import FeedbackForm from "components/Training/Feedback/Form";
import T from "T";
import ReviewModal from "components/Training/ReviewModal/ReviewModal";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  backgroundColor: "white",
  padding: "12px 8px 12px 24px",
  fontSize: 13.2,
  maxWidth: 130,
  overflowWrap: "break-word",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const TaskboxTablebody = ({ allTableRows = [], handleAct = noop, setCurrentFeedBack = noop, refreshTable = noop }) => {
  // const { user } = MISCurrentUser();

  const navigate = useNavigate();

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    showFeedback: false,
    showReviewModal: false,
    currentTask: null,
  });
  const { showFeedback, showReviewModal, currentTask } = localState;
  const handleShowFeedback = () => {
    setLocalState({ showFeedback: !showFeedback });
  };
  const handleShowReviewModal = () => {
    setLocalState({ showReviewModal: !showReviewModal });
  };
  const handleCurrentTask = (task) => {
    setLocalState({ currentTask: task });
  };
  const handleEmptyTask = () => {
    setLocalState({ currentTask: null });
  };

  const handleNavigateToTraining = (currentTask) => {
    console.log(currentTask, "task");
    navigate(`/app/training/${currentTask?.id}/traineeCourse`, {
      state: currentTask,
    });
  };

  const handleCurrentModal = (type, currentTask) => {
    switch (type) {
      case "feedback form":
        handleShowFeedback();
        break;
      case "course review pending":
        handleShowReviewModal();
        break;
      case "course assigned":
        handleNavigateToTraining(currentTask);
        break;
      default:
    }
  };

  return (
    <>
      <TableBody>
        {Children.toArray(
          allTableRows.map((record, index) => {
            const serialNumber = index + 1;
            const name = get(record, "userName", "-");
            const type = get(record, "taskType", "-");
            const task = get(record, "taskType", "-");
            const triggerDate = get(record, "triggerDate", "-");
            const dueDate = get(record, "dueDate", "-");
            const courseName = get(record, "taskTitle", "-");
            return (
              <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
                <StyledTableCell
                  sx={{
                    backgroundColor: "white",
                    pl: 4,
                  }}
                >
                  <Typography>{serialNumber}</Typography>
                </StyledTableCell>

                <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                  <Tooltip title={name} placement="top">
                    <Typography>{name}</Typography>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                  <Tooltip title={courseName} placement="top">
                    <Typography>{courseName}</Typography>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                  <Tooltip title={task} placement="top">
                    <Typography>{task}</Typography>
                  </Tooltip>
                </StyledTableCell>

                <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                  <Tooltip title={triggerDate} placement="top">
                    <Typography>{triggerDate}</Typography>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                  <Tooltip title={dueDate} placement="top">
                    <Typography>{dueDate}</Typography>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                  <MISButton
                    variant={"contained"}
                    startIcon={<NotificationsOutlined sx={{ width: 19 }} />}
                    onClick={() => {
                      const currentTask = get(record, "object", null);
                      handleCurrentTask(currentTask);

                      handleCurrentModal(type, currentTask);
                    }}
                  >
                    {T.ACT}
                  </MISButton>
                </StyledTableCell>
              </StyledTableRow>
            );
          }),
        )}
      </TableBody>
      <FeedbackForm
        showDialog={showFeedback}
        handleShowDialog={handleShowFeedback}
        handleEmptyTask={handleEmptyTask}
        feedback={currentTask}
        refreshTable={refreshTable}
      />
      <ReviewModal
        openReview={showReviewModal}
        assignData={currentTask}
        onHandleClose={handleShowReviewModal}
        handleEmptyTask={handleEmptyTask}
        refreshTable={refreshTable}
      />
    </>
  );
};

TaskboxTablebody.propTypes = {
  allTableRows: PropTypes.array,
};

export default memo(TaskboxTablebody);
