import { Box, Grid, styled, Tooltip } from "@mui/material";
import MISButton from "components/common/MISButton";
import SearchBar from "components/Members/SearchBar";
import { noop } from "lodash";
import { useNavigate } from "react-router-dom";
import routes from "router/routes";
import T from "T";
import { canViewHardware } from "utils/permissions";
import { GET_SIZE } from "utils/responsive";
import HardwareFilters from "../HardwareFilters";
import MISDatePicker from "components/common/MISDatePicker";
import CSV from "assets/CSV.png";
import UploadingTool from "../HardwareUploadTool";

const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const TopBar = ({
  tabValue = "",
  searchInput = "",
  handleKeyChange = noop,
  handleChange = noop,
  isFilterEmpty = false,
  filters = {},
  onhandleFilterChange = noop,
  onHandleDateFilterChange = noop,
  handleFilterSubmit = noop,
  handleFilterClose = noop,
  historyStartDate = null,
  historyEndDate = null,
  onHandleDateChange = noop,
  handleAssignHardwareDialog = noop,
  handleExport = noop,
  bulkHardware = "",
  handleBulkFileUpload = noop,
  handleDeleteFile = noop,
  handleBulkUploadClose = noop,
  handleSubmitUpload = noop,
}) => {
  const { isLg } = GET_SIZE();
  const navigate = useNavigate();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isLg ? "space-between" : "center"}
      sx={{
        mt: 1,
        px: "16px",
        py: 1,
        backgroundColor: "background.card",
        "& .MuiOutlinedInput-input": {
          fontSize: 14,
        },
      }}
    >
      <Grid item md={tabValue === "2" ? 2.5 : 3} xs={12}>
        <SearchBar searchInput={searchInput} handleKeyChange={handleKeyChange} handleChange={handleChange} />
      </Grid>
      {tabValue === "3" && canViewHardware() && (
        <>
          <Grid item md={2} xs={12}>
            <MISDatePicker
              placeholder={T.HISTORY_START_DATE}
              inputFormat="DD/MM/YYYY"
              // value={historyStartDate && getFEDateFormat(historyStartDate)}
              value={historyStartDate}
              name="historyStartDate"
              // minDate={dayjs(minDate)}
              maxDate={historyEndDate}
              handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid>
          -
          <Grid item md={2} xs={12}>
            <MISDatePicker
              placeholder={T.HISTORY_END_DATE}
              inputFormat="DD/MM/YYYY"
              // value={historyEndDate && getFEDateFormat(historyEndDate)}
              value={historyEndDate}
              name="historyEndDate"
              minDate={historyStartDate}
              handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid>
        </>
      )}
      {tabValue === "2" && canViewHardware() && <Grid item md={3} xs={12}></Grid>}
      <Grid item md={tabValue === "2" ? 2.5 : 3} xs={12}>
        <HardwareFilters
          tabValue={tabValue}
          isFilterEmpty={isFilterEmpty}
          filters={filters}
          handleFilterClose={handleFilterClose}
          handleFilterSubmit={handleFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
          onHandleDateFilterChange={onHandleDateFilterChange}
        />
      </Grid>
      <Grid item md={"auto"} display="flex" justifyContent="flex-end">
        <Box display="flex" alignItems="center">
          {tabValue === "2" && canViewHardware() && (
            <UploadingTool
              bulkHardware={bulkHardware}
              handleBulkFileUpload={handleBulkFileUpload}
              handleDeleteFile={handleDeleteFile}
              handleSubmitUpload={handleSubmitUpload}
              handleBulkUploadClose={handleBulkUploadClose}
            />
          )}
          <Tooltip title={T.CSV} placement="top">
            <StyledBox component="img" src={CSV} onClick={() => handleExport()} />
          </Tooltip>
        </Box>
      </Grid>
      {tabValue === "2" && canViewHardware() && (
        <Grid item md={"auto"} xs={12} container justifyContent="flex-end">
          <MISButton size="medium" onClick={() => handleAssignHardwareDialog()}>
            {T.ASSIGN_HARDWARE}
          </MISButton>
        </Grid>
      )}
      {tabValue === "2" && canViewHardware() && (
        <Grid item md={"auto"} xs={12} container justifyContent="flex-end">
          <MISButton size="medium" onClick={() => navigate(routes.app.addHardware)}>
            {T.ADD_HARDWARE}
          </MISButton>
        </Grid>
      )}
    </Grid>
  );
};

export default TopBar;
