import React from "react";
import PropTypes from "prop-types";
import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import { GIFT_TABLE_HEADER } from "../businessCentralModel";
import GiftPagination from "./GiftPagination";
import MISNoData from "components/common/MISNoData";
import GiftTableHeader from "./GiftTableHeader";
import GiftTableBody from "./GiftTableBody";
import GiftGrid from "./GiftGrid";

const Gift = ({
  allTableRows,
  page,
  onPageChange,
  rowsPerPage,
  gifts,
  giftCount,
  selectedGift,
  allSelected,
  handleSelectGifts,
  handleSelectAll,
  onRowsPerPageChange,
  totalTableRowsCount,
  totalPageCount,
  onHandleTableChange,
  onHandleTableAutoComplete,
  handleSaveAsDraft,
  handleSubmit,
  isPresentGiftOpen,
  isGiftHistoryOpen,
  spocName,
  spocId,
  rejectedGiftId,
  isRejectedOpen,
  handleGiftModalOpen,
  handleGiftModalClose,
  handleGiftHistoryOpen,
  handleGiftHistoryClose,
  handleChangeStatus,
  handleOpenReject,
  handleCloseReject,
  refreshData,
}) => {
  return (
    <>
      <GiftGrid giftCount={giftCount} />
      <Card
        elevation={0}
        sx={{
          // p: "0px 8px",
          mt: 0.5,
          backgroundColor: "background.card",
          "& .add-shadow": {
            boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
          },
        }}
      >
        <Box overflow="hidden">
          <TableContainer
            sx={{
              height: "calc(100vh - 345px)",
              overflow: "auto",
            }}
          >
            {allTableRows.length !== 0 ? (
              <TableView stickyHeader>
                <GiftTableHeader columns={GIFT_TABLE_HEADER} allSelected={allSelected} handleSelectAll={handleSelectAll} />
                <GiftTableBody
                  allTableRows={allTableRows}
                  gifts={gifts}
                  selectedGift={selectedGift}
                  handleSelectGifts={handleSelectGifts}
                  onHandleTableChange={onHandleTableChange}
                  onHandleTableAutoComplete={onHandleTableAutoComplete}
                  isPresentGiftOpen={isPresentGiftOpen}
                  isGiftHistoryOpen={isGiftHistoryOpen}
                  spocName={spocName}
                  spocId={spocId}
                  rejectedGiftId={rejectedGiftId}
                  isRejectedOpen={isRejectedOpen}
                  handleGiftModalOpen={handleGiftModalOpen}
                  handleGiftModalClose={handleGiftModalClose}
                  handleGiftHistoryOpen={handleGiftHistoryOpen}
                  handleGiftHistoryClose={handleGiftHistoryClose}
                  handleChangeStatus={handleChangeStatus}
                  handleOpenReject={handleOpenReject}
                  handleCloseReject={handleCloseReject}
                  refreshData={refreshData}
                />
              </TableView>
            ) : (
              <MISNoData />
            )}
          </TableContainer>
        </Box>
        <GiftPagination
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          allSelected={allSelected}
          selectedGift={selectedGift}
          onRowsPerPageChange={onRowsPerPageChange}
          totalTableRowsCount={totalTableRowsCount}
          totalPageCount={totalPageCount}
          handleSaveAsDraft={handleSaveAsDraft}
          handleSubmit={handleSubmit}
        />
      </Card>
    </>
  );
};

export default Gift;
