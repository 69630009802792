import PropTypes from "prop-types";
import noop from "lodash/noop";

import { Table as TableView, Paper, TableContainer, Box, Card } from "@mui/material";

import CandidateRecordTableBody from "./CandidateRecordTableBody";
import MISNoData from "components/common/MISNoData";
import { CAMPUS_PLACEMENT_HEADER } from "../campusPlacementModel";
import MasterPagination from "components/MasterSetting/MasterPagination";
import CandidateRecordHeader from "./CandidateRecordHeader";

const CandidateRecordTable = ({
  totalTableRowsCount,
  totalPageCount,
  page,
  rowsPerPage,
  allTableRows = [],
  onPageChange = noop,
  onRowsPerPageChange = noop,
  refreshTable = noop,
  handleRecordingDialog = noop,
  handleScreenshotDialog = noop,
}) => (
  <Card
    elevation={0}
    sx={{
      p: "0px 8px",
    }}
  >
    <Box overflow="hidden">
      <TableContainer component={Paper} sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}>
        {allTableRows.length !== 0 ? (
          <TableView stickyHeader>
            <CandidateRecordHeader columns={CAMPUS_PLACEMENT_HEADER} />
            <CandidateRecordTableBody
              allTableRows={allTableRows}
              page={page}
              rowsPerPage={rowsPerPage}
              refreshTable={refreshTable}
              handleRecordingDialog={handleRecordingDialog}
              handleScreenshotDialog={handleScreenshotDialog}
            />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>
    </Box>
    <MasterPagination
      page={page}
      rowsPerPage={rowsPerPage}
      totalTableRowsCount={totalTableRowsCount}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  </Card>
);

CandidateRecordTable.propTypes = {
  totalTableRowsCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  allTableRows: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  refreshTable: PropTypes.func,
};

export default CandidateRecordTable;
