import React, { useState } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import { PAGINATION } from "settings/constants/pagination";
import MasterPagination from "components/MasterSetting/MasterPagination";
import TaskboxTablebody from "./TaskboxTablebody";
import TaskboxHeader from "./TaskboxHeader";
import { GET_SIZE } from "utils/responsive";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const TaskboxTable = ({ allTableRows = [], refreshTable = noop }) => {
  const { isLg } = GET_SIZE();
  return (
    <Card
      sx={{
        borderRadius: 2,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        p: 1,
        m: 1,
        height: isLg ? "calc(100vh - 230px)" : "calc(100vh - 450px)",
        overflow: "scroll",
      }}
    >
      <TableContainer sx={{}}>
        {allTableRows.length !== 0 && (
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            <TaskboxHeader />
            <TaskboxTablebody allTableRows={allTableRows} refreshTable={refreshTable} />
          </TableView>
        )}
      </TableContainer>
    </Card>
  );
};

TaskboxTable.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  allTableRows: PropTypes.array,
};
export default TaskboxTable;
