import { useState } from "react";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { Card, Typography } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import ClearIcon from '@mui/icons-material/Clear';
import T from "T";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { savedFilterStore } from "slices/savedFilterSlice";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import FilterOptions from "./FilterOptions";

const BusinessCentralFilter = ({
  userRole,
  isFilterEmpty,
  filters,
  selectedProjects,
  handleFilterClose = noop,
  handleFilterSubmit = noop,
  onhandleFilterChange = noop,
}) => {
  const dispatch = useDispatch();
  const [filterOpen, setFilterOpen] = useState(false);

  const handleFilterClick = () => {
    setFilterOpen(!filterOpen);
  };

  const onFilterClose = (e) => {
    e.stopPropagation()
    dispatch(savedFilterStore({ selectedFilterId: "" }));
    setFilterOpen(false);
    handleFilterClose();
  };

  const onFilterSubmit = () => {
    handleFilterSubmit();
    setFilterOpen(false);
  };
  return (
    <Box>
      <Card
        elevation={0}
        variant="outlined"
        onClick={handleFilterClick}
        sx={{
          p: 1,
          backgroundColor: "background.white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" fontSize={14} fontWeight={isFilterEmpty ? 400 : 600} sx={{ color: isFilterEmpty ? undefined : NETSMARTZ_THEME_COLOR }}>
          {`${T.FILTER}s`}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          {isFilterEmpty ? (
            <FilterAltOutlinedIcon fontSize="small" />
          ) : (
            <>
              <FilterAltOffOutlinedIcon fontSize="small" />
              <ClearIcon onClick={(e) => onFilterClose(e)} fontSize="small" />
            </>
          )}
        </Box>
      </Card>


      {filterOpen && (
        <FilterOptions
          userRole={userRole}
          filters={filters}
          selectedProjects={selectedProjects}
          handleFilterClose={onFilterClose}
          handleFilterSubmit={onFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
          handleClose={() => setFilterOpen(false)}
        />
      )}
    </Box>
  );
};

BusinessCentralFilter.propTypes = {
  skillList: PropTypes.object,
  filters: PropTypes.object,
  status: PropTypes.array,
  clientName: PropTypes.array,
  pMResults: PropTypes.array,
  onhandleFilterChange: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterClose: PropTypes.func,
  isFilterEmpty: PropTypes.bool,
};

export default BusinessCentralFilter;
