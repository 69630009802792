import { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import { BUSINESS_CENTRAL_TABLE_HEADER } from "../businessCentralModel";
import MasterPagination from "components/MasterSetting/MasterPagination";
import MISNoData from "components/common/MISNoData";
import TableHeader from "./TableHeader";
import MISTableBody from "./TableBody";
import { SORTING_KEYS } from "../businessCentralModel";
import { orderBy } from "lodash";
const Table = ({
  allTableRows,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  totalTableRowsCount,
  totalPageCount,
  deleteProjectOnId,
  isConfirmSubmitOpen,
  handleOpenConfirmSubmit,
  handleCloseConfirmSubmit,
}) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    activeSort: "",
    sortDirection: 0,
    renderedRow: [],
  });

  const { activeSort, sortDirection, renderedRow } = localState;

  useEffect(() => {
    setLocalState({ renderedRow: allTableRows, activeSort: "", sortDirection: 0 });
  }, [allTableRows]);

  const handleToolsSorting = (val) => {
    setLocalState({ activeSort: val });
    let current = sortDirection;
    if (current === 0) current = 1;
    else if (current === 1) current = -1;
    else if (current === -1) current = 0;
    if (activeSort != val) current = 1;
    setLocalState({ sortDirection: current });

    if (current === 0) {
      setLocalState({ renderedRow: allTableRows });
      return;
    }

    const order = current === 1 ? "asc" : "desc";
    const sortedRows = orderBy(allTableRows, [SORTING_KEYS.find((item) => item.key === val).value], [order]);
    setLocalState({ renderedRow: sortedRows });
  };

  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          sx={{
            height: "calc(100vh - 275px)",
            overflow: "auto",
          }}
        >
          {allTableRows.length !== 0 ? (
            <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
              <TableHeader
                columns={BUSINESS_CENTRAL_TABLE_HEADER}
                activeSort={activeSort}
                sortDirection={sortDirection}
                handleToolsSorting={handleToolsSorting}
              />
              <MISTableBody
                allTableRows={renderedRow}
                isConfirmSubmitOpen={isConfirmSubmitOpen}
                handleOpenConfirmSubmit={handleOpenConfirmSubmit}
                handleCloseConfirmSubmit={handleCloseConfirmSubmit}
                deleteProjectOnId={deleteProjectOnId}
              />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>
      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

Table.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  totalTableRowsCount: PropTypes.number,
  allTableRows: PropTypes.array,
  handleJdDialog: PropTypes.func,
  handleDeleteProjectDialog: PropTypes.func,
  deleteProjectOnId: PropTypes.func,
  isConfirmSubmitOpen: PropTypes.bool,
  handleOpenConfirmSubmit: PropTypes.func,
  handleCloseConfirmSubmit: PropTypes.func,
};
export default Table;
